import * as Types from "../types/api.generated";

import { TypedDocumentNode as DocumentNode } from "@graphql-typed-document-node/core";
export type UserProfileStateQueryVariables = Types.Exact<{
    user: Types.Scalars["String"];
}>;

export type UserProfileStateQuery = {
    diveLogs?: Array<{ feature?: { id: string } | null } | null> | null;
};

export const UserProfileStateDocument = {
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "query",
            name: { kind: "Name", value: "userProfileState" },
            variableDefinitions: [
                {
                    kind: "VariableDefinition",
                    variable: {
                        kind: "Variable",
                        name: { kind: "Name", value: "user" },
                    },
                    type: {
                        kind: "NonNullType",
                        type: {
                            kind: "NamedType",
                            name: { kind: "Name", value: "String" },
                        },
                    },
                },
            ],
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {
                        kind: "Field",
                        name: { kind: "Name", value: "diveLogs" },
                        arguments: [
                            {
                                kind: "Argument",
                                name: { kind: "Name", value: "user" },
                                value: {
                                    kind: "Variable",
                                    name: { kind: "Name", value: "user" },
                                },
                            },
                        ],
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "feature" },
                                    selectionSet: {
                                        kind: "SelectionSet",
                                        selections: [
                                            {
                                                kind: "Field",
                                                name: {
                                                    kind: "Name",
                                                    value: "id",
                                                },
                                            },
                                        ],
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<
    UserProfileStateQuery,
    UserProfileStateQueryVariables
>;
