<script lang="ts" setup>
import TextField from "./fields/TextField.vue";
import { ref } from "vue";
import client from "src/client";
import { SendAuthTokenEmailDocument } from "src/api/sendAuthTokenEmail.generated";
import { isApolloError } from "@apollo/client/core";
import { isError } from "src/error";

const emailAddress = ref("");
const isBusy = ref(false);

const emit = defineEmits<{
    (e: "success", payload: { emailAddress: string }): void;
}>();

async function handleLogin() {
    isBusy.value = true;

    try {
        await client.mutate({
            mutation: SendAuthTokenEmailDocument,
            variables: { emailAddress: emailAddress.value },
        });
    } catch (e) {
        if (!isError(e)) {
            alert("An unknown error occurred");
            isBusy.value = false;
            return;
        }
        if (!isApolloError(e)) {
            alert("An unknown error occurred");
            isBusy.value = false;
            return;
        }
        (window as any).e = e;
        alert("Could not send login email, check the address and try again.");
        isBusy.value = false;
        return;
    }

    isBusy.value = false;
    emit("success", { emailAddress: emailAddress.value });
}
</script>

<template>
    <form @submit.prevent="handleLogin">
        <TextField
            type="email"
            label="Email Address"
            v-model="emailAddress"
            required
            autocomplete="email"
            :disabled="isBusy"
        />
        <div class="controls flex flex-row">
            <p class="flex-1 py-2.5 text-sm opacity-50 hover:opacity-90">
                <router-link :to="{ name: 'login-manual' }" class="link">
                    Login with password
                </router-link>
            </p>
            <p>
                <button
                    class="btn btn-primary"
                    type="submit"
                    :disabled="isBusy"
                >
                    Log in
                </button>
            </p>
        </div>
    </form>
</template>
