<script lang="ts" setup>
import { useDivemapHead } from "src/util/unhead";

useDivemapHead({ title: "Attribution" });

const attributions = [
    {
        text: "© OpenStreetMap contributors",
        href: "https://openstreetmap.org/copyright",
    },
    {
        text: "© Mapbox",
        href: "https://www.mapbox.com/about/maps/",
    },
    {
        text: "Contains OS data © Crown copyright and database right 2022",
        href: "https://www.ordnancesurvey.co.uk/opendatadownload/products.html",
    },
    {
        text: "GEBCO Compilation Group (2022) GEBCO_2022 Grid (doi:10.5285/e0f0bb80-ab44-2739-e053-6c86abc0289c)",
        href: "https://www.gebco.net/data_and_products/gridded_bathymetry_data/",
    },
    {
        text: "Contains public sector information licensed under the Open Government Licence v3.0",
        href: "https://www.nationalarchives.gov.uk/doc/open-government-licence/version/3/",
    },
    {
        text: "Contains ADMIRALTY ® chart data: © Crown copyright and database right",
        href: "https://www.admiralty.co.uk/",
    },
];
</script>

<template>
    <main class="page">
        <p class="my-4">
            <RouterLink :to="{ name: 'map' }" class="link">
                <font-awesome-icon
                    :icon="['far', 'chevrons-left']"
                    class="mr-1"
                />
                <span class="underline">Back to Map</span>
            </RouterLink>
        </p>

        <h1 class="heading">Attribution</h1>
        <p class="my-4">
            divemap contains data from multiple sources, specific sources are
            listed on each site. Listed below are data sources used more
            generally:
        </p>
        <ul class="list-disc">
            <li v-for="attribution in attributions" class="ml-6">
                <a
                    :href="attribution.href"
                    class="link"
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    {{ attribution.text }}
                </a>
            </li>
        </ul>
    </main>
</template>
