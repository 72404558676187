import * as Types from "../types/api.generated";

import { TypedDocumentNode as DocumentNode } from "@graphql-typed-document-node/core";
import { UserFieldsFragmentDoc } from "./user.fragments.generated";
export type LoginWithEmailTokenMutationVariables = Types.Exact<{
    emailAuthToken: Types.Scalars["String"];
}>;

export type LoginWithEmailTokenMutation = {
    emailTokenAuth: {
        token?: string | null;
        payload?: any | null;
        user?: {
            id: string;
            username: string;
            email: string;
            isSuperuser: boolean;
            permissions: Array<string>;
        } | null;
    };
};

export const LoginWithEmailTokenDocument = {
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "mutation",
            name: { kind: "Name", value: "loginWithEmailToken" },
            variableDefinitions: [
                {
                    kind: "VariableDefinition",
                    variable: {
                        kind: "Variable",
                        name: { kind: "Name", value: "emailAuthToken" },
                    },
                    type: {
                        kind: "NonNullType",
                        type: {
                            kind: "NamedType",
                            name: { kind: "Name", value: "String" },
                        },
                    },
                },
            ],
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {
                        kind: "Field",
                        name: { kind: "Name", value: "emailTokenAuth" },
                        arguments: [
                            {
                                kind: "Argument",
                                name: { kind: "Name", value: "emailAuthToken" },
                                value: {
                                    kind: "Variable",
                                    name: {
                                        kind: "Name",
                                        value: "emailAuthToken",
                                    },
                                },
                            },
                        ],
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "user" },
                                    selectionSet: {
                                        kind: "SelectionSet",
                                        selections: [
                                            {
                                                kind: "FragmentSpread",
                                                name: {
                                                    kind: "Name",
                                                    value: "UserFields",
                                                },
                                            },
                                        ],
                                    },
                                },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "token" },
                                },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "payload" },
                                },
                            ],
                        },
                    },
                ],
            },
        },
        {
            kind: "FragmentDefinition",
            name: { kind: "Name", value: "UserFields" },
            typeCondition: {
                kind: "NamedType",
                name: { kind: "Name", value: "User" },
            },
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    { kind: "Field", name: { kind: "Name", value: "id" } },
                    {
                        kind: "Field",
                        name: { kind: "Name", value: "username" },
                    },
                    { kind: "Field", name: { kind: "Name", value: "email" } },
                    {
                        kind: "Field",
                        name: { kind: "Name", value: "isSuperuser" },
                    },
                    {
                        kind: "Field",
                        name: { kind: "Name", value: "permissions" },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<
    LoginWithEmailTokenMutation,
    LoginWithEmailTokenMutationVariables
>;
