import mitt, { Handler } from "mitt";
import { onBeforeUnmount } from "vue";
import { DarkroomAssets } from "./components/darkroom/types";
import { TileMapType } from "./maps/tiles";
import { MapStyle } from "./maps/types";
import { Position, UserLogin } from "./types";

interface AppReadyEvent {
    loggedIn: boolean;
}

export enum FlyToZoomMode {
    Auto = "auto",
    Step = "step",
}

export type FlyToEvent = {
    position: Position;
    zoomMode?: FlyToZoomMode;
};

export interface BaseSelectEvent {
    id: string;
    name: string;
    position: Position;
}

export interface ShowDarkroomEvent {
    assets: DarkroomAssets;
    selectedAssetId?: string;
}

export type Events = {
    StoreReady: undefined;
    AppReady: AppReadyEvent;
    UserLogin: UserLogin;
    UserLogout: void;
    UserRegistered: UserLogin;
    // Called when user searches using the search bar
    SearchStart: string;
    // Called when feature is selected by user from search or other means
    FeatureSelect: BaseSelectEvent;
    // Called when feature is clicked on map
    FeatureSelectFromMap: string;
    // Called when feature has been selected, and when app is loaded with a feature selected
    FeatureSelected: BaseSelectEvent;
    // Called when feature is deselected
    FeatureDeselect: string;
    // Called when a place is selected by user from search or other means
    PlaceSelect: BaseSelectEvent;
    // Called when a place is deselected
    PlaceDeselect: string;
    // Make the map fly to a position
    FlyTo: FlyToEvent;
    // Map's style has changed
    MapStyleChanged: MapStyle;
    // Want to reload map source data
    MapReloadSources: void;
    ShowDarkroom: ShowDarkroomEvent;
    ChangeTileMap: TileMapType;
};

const bus = mitt<Events>();

export default bus;

// Bind to an event in a component
export function onEvent<Key extends keyof Events>(
    type: Key,
    handler: Handler<Events[Key]>,
) {
    bus.on(type, handler);
    onBeforeUnmount(() => bus.off(type, handler));
}

// Use this to emit events from outside of Vue components
export function onEventGlobal<Key extends keyof Events>(
    type: Key,
    handler: Handler<Events[Key]>,
) {
    bus.on(type, handler);
}
