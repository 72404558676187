<script lang="ts" setup>
import RegisterUserForm from "src/components/RegisterUserForm.vue";
import { useDivemapHead } from "src/util/unhead";
import { useRouter } from "vue-router";

useDivemapHead({ title: "Register" });

const token = new URLSearchParams(window.location.search).get("t") ?? "";
const router = useRouter();

if (token === "") {
    router.push({ name: "login" });
}
</script>

<template>
    <main class="bg-primary-stripes grid h-screen place-items-center">
        <RegisterUserForm
            :token="token"
            class="w-full max-w-sm rounded bg-white px-8 py-6 pb-8 shadow"
        />
    </main>
</template>
