import { onEventGlobal } from "src/events";
import store from "src/store";
import {
    initPosthog,
    trackEvent as posthogTrackEvent,
    identifyUser as posthogIdentifyUser,
    resetUser as posthogResetUser,
} from "./posthog";
import { initPlausible, trackEvent as plausibleTrackEvent } from "./plausible";
import { identifyUser as sentryIdentifyUser } from "./sentry";

export function initUserTracking() {
    initPlausible();
    initPosthog();
    registerTrackedEvents();

    // Identify user when store ready or when they log in
    onEventGlobal("StoreReady", () => {
        if (!store.state.user) return;
        // @ts-ignore, store object is readonly but identifyUser expects a mutable version, can't be arsed to fix now
        posthogIdentifyUser(store.state.user);
        // @ts-ignore Same as above
        sentryIdentifyUser(store.state.user);
    });
    onEventGlobal("UserLogin", (user) => {
        posthogIdentifyUser(user);
        sentryIdentifyUser(user);
    });
    onEventGlobal("UserRegistered", (user) => {
        // Need to alias the user's anonymous ID to their new ID before we identify them
        posthogIdentifyUser(user);
        sentryIdentifyUser(user);
    });
    onEventGlobal("UserLogout", () => {
        posthogResetUser();
    });
}

function registerTrackedEvents() {
    onEventGlobal("AppReady", ({ loggedIn }) =>
        trackEvent("App Loaded", { loggedIn }),
    );
    onEventGlobal("UserLogin", (user) =>
        trackEvent("Login", { userId: user.id, username: user.username }),
    );
    onEventGlobal("UserRegistered", (user) =>
        trackEvent("User registered", {
            userId: user.id,
            username: user.username,
        }),
    );
    onEventGlobal("UserLogout", () => trackEvent("Logout"));
    onEventGlobal("SearchStart", (query) =>
        trackEvent("Searched for", { query }),
    );
    onEventGlobal("FeatureSelect", (feature) =>
        trackEvent("Feature selected", {
            featureId: feature.id,
            featureName: feature.name,
        }),
    );
    onEventGlobal("FeatureSelectFromMap", (featureId: string) =>
        trackEvent("Feature selected from map", { featureId: featureId }),
    );
    onEventGlobal("FeatureDeselect", (feature) =>
        trackEvent("Feature deselected", { featureId: feature }),
    );
    onEventGlobal("PlaceSelect", (place) =>
        trackEvent("Place selected", {
            placeId: place.id,
            placeName: place.name,
        }),
    );
    onEventGlobal("PlaceDeselect", (place) =>
        trackEvent("Place deselected", { placeId: place }),
    );
    onEventGlobal("FlyTo", (flyTo) =>
        trackEvent("Map fly to", { zoomMode: flyTo.zoomMode || "" }),
    );
    onEventGlobal("MapStyleChanged", (style) =>
        trackEvent("Map style changed", { style }),
    );
    onEventGlobal("ChangeTileMap", (tileMapType) =>
        trackEvent("Changed tile map", { tileMapType }),
    );
    onEventGlobal("MapReloadSources", () => trackEvent("Map sources reloaded"));
    onEventGlobal("ShowDarkroom", () => trackEvent("Show media darkroom"));
}

export function trackEvent(
    eventName: string,
    props: Record<string, any> = {},
): void {
    plausibleTrackEvent(eventName, { props });
    posthogTrackEvent(eventName, props);
}
