import {
    ApolloClient,
    createHttpLink,
    InMemoryCache,
} from "@apollo/client/core";
import { setContext } from "@apollo/client/link/context";
import store from "./store";

export const DEFAULT_FETCH_POLICY = "network-only";

const httpLink = createHttpLink({
    uri: import.meta.env.VITE_GQL_URL
        ? import.meta.env.VITE_GQL_URL
        : import.meta.env.VITE_API_HOST + "/gql",
});

const authLink = setContext((_, { headers }) => {
    // get the authentication token from the store if exists
    const token = store.state.authToken;
    // return the headers to the context so httpLink can read them
    headers = {
        ...headers,
        authorization: token ? `JWT ${token}` : "",
    };
    if (store.state.distinctId) {
        headers["x-ph"] = store.state.distinctId;
    }
    return { headers };
});

const client = new ApolloClient({
    link: authLink.concat(httpLink),
    cache: new InMemoryCache(),
});

export default client;
