import * as Types from "../types/api.generated";

import { TypedDocumentNode as DocumentNode } from "@graphql-typed-document-node/core";
export type AuthCheckQueryVariables = Types.Exact<{ [key: string]: never }>;

export type AuthCheckQuery = { authCheck: { success: boolean } };

export const AuthCheckDocument = {
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "query",
            name: { kind: "Name", value: "authCheck" },
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {
                        kind: "Field",
                        name: { kind: "Name", value: "authCheck" },
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "success" },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<AuthCheckQuery, AuthCheckQueryVariables>;
