import {
    createRouter,
    createWebHistory,
    RouteLocationNormalized,
    RouteRecordRaw,
} from "vue-router";
import { updateQueryStringFromState } from "./maps/state";
import About from "./views/About.vue";
import Attribution from "./views/Attribution.vue";
import AuthEmailLogin from "./views/AuthEmailLogin.vue";
import AuthEmailToken from "./views/AuthEmailToken.vue";
import AuthManualLogin from "./views/AuthManualLogin.vue";
import AuthRegisterUser from "./views/AuthRegisterUser.vue";
import PageNotFound from "./views/PageNotFound.vue";

const mapBundle = async () => import("./bundles/map.js");
const editorBundle = async () => import("./bundles/editor.js");
const debugBundle = async () => import("./bundles/debug.js");

function getPanePath(prefix: string) {
    return `:id(${prefix}[0-9A-z-_~]+)`;
}

export const routes: RouteRecordRaw[] = [
    {
        path: "/",
        component: async () => (await mapBundle()).Map,
        name: "map",
        children: [
            {
                path: getPanePath("f"),
                name: "feature-pane",
                props: true,
                component: async () => (await mapBundle()).FeaturePane,
            },
            {
                path: getPanePath("f") + "/user",
                name: "feature-user-ref-pane",
                props: true,
                component: async () =>
                    (await mapBundle()).ReferenceUserFormPane,
            },
            {
                path: getPanePath("p"),
                name: "place-pane",
                props: true,
                component: async () => (await mapBundle()).PlacePane,
            },
        ],
    },
    {
        path: "/:id(f[0-9A-z-_~]+)/detail",
        name: "feature-detail",
        props: true,
        component: async () => (await editorBundle()).FeatureDetail,
    },
    {
        path: "/r/new",
        name: "reference-create",
        component: async () => (await editorBundle()).ReferenceCreate,
    },
    {
        path: "/:featureId(f[0-9A-z-_~]+)/r/new",
        name: "reference-create-on-feature",
        props: true,
        component: async () => (await editorBundle()).ReferenceCreate,
    },
    {
        path: "/:id(r[0-9A-z-_~]+)/edit",
        name: "reference-edit",
        props: true,
        component: async () => (await editorBundle()).ReferenceEdit,
    },
    {
        path: "/:id(r[0-9A-z-_~]+)/assets",
        name: "reference-asset-list",
        props: true,
        component: async () => (await editorBundle()).ReferenceAssetList,
    },
    {
        path: "/:id(r[0-9A-z-_~]+)/assets/new",
        name: "reference-asset-create",
        props: true,
        component: async () => (await editorBundle()).ReferenceAssetCreate,
    },
    {
        path: "/assets/:id(a[0-9A-z-_~]+)",
        name: "reference-asset-edit",
        props: true,
        component: async () => (await editorBundle()).ReferenceAssetEdit,
    },
    {
        path: "/:id(f[0-9A-z-_~]+)/video/new",
        name: "feature-video-add",
        props: true,
        component: async () => (await editorBundle()).FeatureVideoAdd,
    },
    { path: "/about", component: About, name: "about" },
    { path: "/attribution", component: Attribution, name: "attribution" },
    { path: "/login", component: AuthEmailLogin, name: "login" },
    {
        path: "/login/password",
        component: AuthManualLogin,
        name: "login-manual",
    },
    { path: "/auth", component: AuthEmailToken, name: "auth-email-token" },
    { path: "/register", component: AuthRegisterUser, name: "register" },
    {
        path: "/debug/",
        name: "debug-index",
        component: async () => (await debugBundle()).DebugIndex,
        children: [
            {
                path: "components",
                name: "debug-components",
                component: async () => (await debugBundle()).ComponentLibrary,
            },
            {
                path: "gql",
                name: "debug-gql",
                component: async () => (await debugBundle()).DebugGraphQL,
            },
            {
                path: "auth",
                name: "debug-auth",
                component: async () => (await debugBundle()).DebugAuth,
            },
            {
                path: "env",
                name: "debug-env",
                component: async () => (await debugBundle()).DebugEnv,
            },
            {
                path: "colours",
                name: "debug-colours",
                component: async () => (await debugBundle()).DebugColours,
            },
            {
                path: "tracking",
                name: "debug-tracking",
                component: async () => (await debugBundle()).DebugTracking,
            },
            {
                path: "mapbox",
                name: "debug-mapbox",
                component: async () => (await debugBundle()).DebugMapbox,
            },
            {
                path: "navionics",
                name: "debug-navionics",
                component: async () => (await debugBundle()).DebugNavionics,
            },
            {
                path: "email",
                name: "debug-email",
                component: async () => (await debugBundle()).DebugEmail,
            },
        ],
    },
    {
        path: "/:pathMatch(.*)*",
        name: "page-not-found",
        component: PageNotFound,
    },
];

const router = createRouter({
    history: createWebHistory(),
    routes,
});

// Returns the parent route of the current route if exists
export function getParentRoute(route: RouteLocationNormalized) {
    const parent = route.matched[route.matched.length - 2];
    return parent ? parent : null;
}

// Maintain query string when navigating to map routes
router.afterEach((to, from) => {
    // Skip if not map route or child of map route
    if (to.name !== "map" && getParentRoute(to)?.name !== "map") {
        return;
    }
    // Update the query string with the current state
    updateQueryStringFromState();
});

export default router;
