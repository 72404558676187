<script lang="ts" setup>
import { FetchResult } from "@apollo/client/core";
import { isApolloError } from "@apollo/client/errors";
import {
    LoginWithEmailTokenDocument,
    LoginWithEmailTokenMutation,
} from "src/api/loginWithEmailToken.generated";
import client from "src/client";
import LoadingSpinner from "src/components/LoadingSpinner.vue";
import { isError } from "src/error";
import { useRouter } from "vue-router";
import { isNotNull, isNotUndefined } from "typed-assert";
import store from "src/store";
import { useDivemapHead } from "src/util/unhead";

useDivemapHead({ title: "Login" });

const router = useRouter();

async function handleEmailAuthToken() {
    const emailAuthToken = new URLSearchParams(window.location.search).get("t");
    if (!emailAuthToken) {
        alert("No email token found in URL");
        router.push({ name: "login" });
        return;
    }

    let response: FetchResult<LoginWithEmailTokenMutation>;

    // Hit server with token and get either:
    // - a new token if user exists
    // - knowlege that user doesn't exist
    // - an error
    try {
        response = await client.mutate({
            mutation: LoginWithEmailTokenDocument,
            variables: { emailAuthToken },
        });
    } catch (e) {
        if (!isError(e)) {
            alert("An unknown error occurred");
            router.push({ name: "login" });
            return;
        }
        if (!isApolloError(e)) {
            alert("An unknown error occurred");
            router.push({ name: "login" });
            return;
        }
        (window as any).e = e;
        alert("Could not login, please try again");
        // router.push({ name: 'login' });
        return;
    }
    const data = response.data?.emailTokenAuth;
    if (!data) {
        alert("Server returned no data");
        router.push({ name: "login" });
        return;
    }

    if (data.token) {
        // User exists
        isNotUndefined(data.token);
        isNotNull(data.token);
        isNotUndefined(data.payload);
        isNotNull(data.payload);
        isNotUndefined(data.user);
        isNotNull(data.user);
        store.setAuth(data.token, data.payload);
        store.setUser(data.user);
        router.push({ name: "map" });
    } else {
        // User doesn't exist
        router.push({ name: "register", query: { t: emailAuthToken } });
    }
}

handleEmailAuthToken();
</script>

<template>
    <LoadingSpinner
        class="bg-primary-900"
        iconClass="text-white"
        text="Logging you in…"
    />
</template>
