<template>
    <form @submit.prevent="handleLogin">
        <TextField
            label="Username"
            v-model="username"
            required
            autocomplete="username"
            @input="loginFailed = false"
        />
        <TextField
            type="password"
            label="Password"
            v-model="password"
            required
            autocomplete="password"
            @input="loginFailed = false"
        />
        <Error v-if="loginFailed" class="message mb-4">
            Your details were incorrect, please try again
        </Error>
        <div class="controls text-right">
            <button class="btn btn-primary" type="submit" :disabled="isBusy">
                Log in
            </button>
        </div>
    </form>
</template>

<script lang="ts">
import { doLogin } from "src/auth";
import { defineComponent, ref } from "vue";
import Error from "./Error.vue";
import TextField from "./fields/TextField.vue";

export default defineComponent({
    components: { Error, TextField },
    setup() {
        const isBusy = ref(false);
        const loginFailed = ref(false);
        const username = ref("");
        const password = ref("");
        const handleLogin = async () => {
            loginFailed.value = false;
            isBusy.value = true;
            const success = await doLogin(username.value, password.value);
            loginFailed.value = !success;
            isBusy.value = false;
        };
        return {
            isBusy,
            loginFailed,
            username,
            password,
            handleLogin,
        };
    },
});
</script>
