import { library } from "@fortawesome/fontawesome-svg-core";
import { faTimes as falTimes } from "@fortawesome/pro-light-svg-icons";
import {
    faAnchor,
    faArrowAltRight,
    faArrowAltToBottom,
    faArrowAltToTop,
    faBan,
    faChevronLeft,
    faChevronRight,
    faChevronsLeft,
    faCity,
    faExclamationCircle,
    faExclamationTriangle,
    faExternalLink,
    faGlobe,
    faHillRockslide,
    faHouseBuilding,
    faHouseWindow,
    faImage,
    faIslandTropical,
    faLocation,
    faLocationDot,
    faMap,
    faMound,
    faMountain,
    faMountains,
    faPaste,
    faRedoAlt,
    faSatellite,
    faSearch,
    faSpinnerThird,
    faTimes,
    faTrees,
    faTriangle,
    faUmbrellaBeach,
    faUser,
    faWater,
} from "@fortawesome/pro-regular-svg-icons";
import {
    faAnchor as fasAnchor,
    faCaretDown as fasCaretDown,
    faCaretRight as fasCaretRight,
    faMound as fasMound,
    faMountain as fasMountain,
    faUser as fasUser,
} from "@fortawesome/pro-solid-svg-icons";

// Sort these lines alphabetically
library.add(faAnchor);
library.add(faArrowAltRight);
library.add(faArrowAltToBottom);
library.add(faArrowAltToTop);
library.add(faBan);
library.add(faChevronsLeft);
library.add(faCity);
library.add(faExclamationCircle);
library.add(faExclamationTriangle);
library.add(faExternalLink);
library.add(faGlobe);
library.add(faHillRockslide);
library.add(faHouseBuilding);
library.add(faHouseWindow);
library.add(faIslandTropical);
library.add(faLocation);
library.add(faLocationDot);
library.add(falTimes);
library.add(faMap);
library.add(faMound);
library.add(faMountain);
library.add(faMountains);
library.add(faPaste);
library.add(faRedoAlt);
library.add(fasAnchor);
library.add(faSatellite);
library.add(fasCaretDown);
library.add(fasCaretRight);
library.add(faSearch);
library.add(fasMound);
library.add(fasMountain);
library.add(faSpinnerThird);
library.add(fasUser);
library.add(faTimes);
library.add(faTrees);
library.add(faTriangle);
library.add(faUmbrellaBeach);
library.add(faUser);
library.add(faWater);
library.add(faChevronLeft);
library.add(faChevronRight);
library.add(faImage);
