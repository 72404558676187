import Plausible, { EventOptions, PlausibleOptions } from "plausible-tracker";

let plausible: ReturnType<typeof Plausible>;

export function initPlausible() {
    const domain = import.meta.env.VITE_PLAUSIBLE_DOMAIN;
    if (!domain) return;

    plausible = Plausible({
        domain,
        trackLocalhost: true,
    });

    plausible.enableAutoPageviews();
}

export async function trackEvent(
    eventName: string,
    options?: EventOptions,
    eventData?: PlausibleOptions,
) {
    if (!plausible) return;
    plausible.trackEvent(eventName, options, eventData);
}
