export interface MapCameraPosition {
    lat: number;
    lng: number;
    zoom: number;
}

export enum MapStyle {
    MAP = "m",
    SAT = "s",
    CHART = "c",
}
