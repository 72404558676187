<template>
    <main class="page">
        <h1 class="heading">Page not found</h1>
    </main>
</template>

<script lang="ts">
import { trackEvent } from "src/service/analytics";
import { defineComponent, onMounted } from "vue";

export default defineComponent({
    setup() {
        onMounted(() => {
            trackEvent("404");
        });
    },
});
</script>
