import * as Types from "../types/api.generated";

import { TypedDocumentNode as DocumentNode } from "@graphql-typed-document-node/core";
export type RefreshTokenMutationVariables = Types.Exact<{
    token: Types.Scalars["String"];
}>;

export type RefreshTokenMutation = {
    refreshToken: { token: string; payload: any; refreshExpiresIn: number };
};

export const RefreshTokenDocument = {
    kind: "Document",
    definitions: [
        {
            kind: "OperationDefinition",
            operation: "mutation",
            name: { kind: "Name", value: "refreshToken" },
            variableDefinitions: [
                {
                    kind: "VariableDefinition",
                    variable: {
                        kind: "Variable",
                        name: { kind: "Name", value: "token" },
                    },
                    type: {
                        kind: "NonNullType",
                        type: {
                            kind: "NamedType",
                            name: { kind: "Name", value: "String" },
                        },
                    },
                },
            ],
            selectionSet: {
                kind: "SelectionSet",
                selections: [
                    {
                        kind: "Field",
                        name: { kind: "Name", value: "refreshToken" },
                        arguments: [
                            {
                                kind: "Argument",
                                name: { kind: "Name", value: "token" },
                                value: {
                                    kind: "Variable",
                                    name: { kind: "Name", value: "token" },
                                },
                            },
                        ],
                        selectionSet: {
                            kind: "SelectionSet",
                            selections: [
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "token" },
                                },
                                {
                                    kind: "Field",
                                    name: { kind: "Name", value: "payload" },
                                },
                                {
                                    kind: "Field",
                                    name: {
                                        kind: "Name",
                                        value: "refreshExpiresIn",
                                    },
                                },
                            ],
                        },
                    },
                ],
            },
        },
    ],
} as unknown as DocumentNode<
    RefreshTokenMutation,
    RefreshTokenMutationVariables
>;
