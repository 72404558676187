<template>
    <div class="grid gap-1.5 bg-red-600 px-2 py-1 text-white">
        <div>
            <font-awesome-icon :icon="['far', 'exclamation-circle']" />
        </div>
        <div class="font-semibold">
            <slot></slot>
        </div>
    </div>
</template>

<style lang="sass" scoped>
.grid
 grid-template-columns: 1em 1fr
</style>
