<script lang="ts" setup>
import { doLogout } from "src/auth";
import store from "src/store";
import EmailLoginForm from "src/components/EmailLoginForm.vue";
import { ref } from "vue";
import { useDivemapHead } from "src/util/unhead";
useDivemapHead({ title: "Login" });

const handleLogOut = async () => doLogout();
const loginFormSuccessEmail = ref<string>();
</script>

<template>
    <main class="bg-primary-stripes grid h-screen place-items-center">
        <EmailLoginForm
            v-if="!store.state.loggedIn && !loginFormSuccessEmail"
            @success="loginFormSuccessEmail = $event.emailAddress"
            class="w-full max-w-sm rounded bg-white px-8 py-6 pb-8 shadow"
        />
        <div
            v-else-if="loginFormSuccessEmail"
            class="w-full max-w-sm rounded bg-white px-8 py-6 shadow"
        >
            <p>
                An email was sent to <b>{{ loginFormSuccessEmail }}</b> with a
                link to either login or create an account.
            </p>
        </div>
        <div v-else class="rounded bg-white px-8 py-6 text-center shadow">
            <p class="mb-4">You are logged in as {{ store.state.username }}</p>
            <button class="btn btn-primary" @click="handleLogOut">
                Log Out
            </button>
        </div>
    </main>
</template>
