<script lang="ts" setup>
import { doLogout } from "src/auth";
import LoginForm from "src/components/LoginForm.vue";
import store from "src/store";
import { useDivemapHead } from "src/util/unhead";

useDivemapHead({ title: "Login" });

const handleLogOut = async () => doLogout();
</script>

<template>
    <main class="bg-primary-stripes grid h-screen place-items-center">
        <LoginForm
            v-if="!store.state.loggedIn"
            class="w-full max-w-sm rounded bg-white px-8 py-6 pb-8 shadow"
        />
        <div v-else class="rounded bg-white px-8 py-6 text-center shadow">
            <p class="mb-4">You are logged in as {{ store.state.username }}</p>
            <button class="btn btn-primary" @click="handleLogOut">
                Log Out
            </button>
        </div>
    </main>
</template>
