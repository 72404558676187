import { Style } from "maplibre-gl";
import { hasPermission } from "src/auth";

export enum TileMapType {
    OpenStreetMap = "OpenStreetMap",
    CmtInt1 = "CmtInt1",
    CmtInt1Hrb = "CmtInt1Hrb",
    // CmtInt1HrbShadedRelief = "CmtInt1HrbShadedRelief",
    CmtInt1Mars = "CmtInt1Mars",
    CmtInt1HrbMars = "CmtInt1HrbMars",
    CmtInt1HrbShadedReliefMars = "CmtInt1HrbShadedReliefMars",
}

const TILES_PUBLIC: TileMapType[] = [TileMapType.OpenStreetMap];

const TILES_PRIVATE: TileMapType[] = [
    TileMapType.CmtInt1,
    TileMapType.CmtInt1Hrb,
    // TileMapType.CmtInt1HrbShadedRelief,
];

const TILES_INTERNAL: TileMapType[] = [
    TileMapType.CmtInt1Mars,
    TileMapType.CmtInt1HrbMars,
    TileMapType.CmtInt1HrbShadedReliefMars,
];

export function getUserTileMapTypes(): TileMapType[] {
    const tiles = [...TILES_PUBLIC];
    if (hasPermission("users.charts")) {
        tiles.push(...TILES_PRIVATE);
    }
    if (hasPermission("users.charts-mars")) {
        tiles.push(...TILES_INTERNAL);
    }
    return tiles;
}

export interface TileMap {
    url: string;
    maxZoom: number;
}

export const TILE_MAPS: Record<TileMapType, TileMap> = {
    [TileMapType.OpenStreetMap]: {
        url: "https://tile.openstreetmap.org/{z}/{x}/{y}.png",
        maxZoom: 19,
    },
    [TileMapType.CmtInt1]: {
        url: "https://tiles.divemap.uk/CmtInt1/{z}/{x}/{y}.png",
        maxZoom: 16,
    },
    [TileMapType.CmtInt1Hrb]: {
        url: "https://tiles.divemap.uk/CmtInt1Hrb/{z}/{x}/{y}.png",
        maxZoom: 15,
    },
    [TileMapType.CmtInt1Mars]: {
        url: "https://mars.tiles.divemap.uk/CmtInt1/{z}/{x}/{y}.png",
        maxZoom: 16,
    },
    [TileMapType.CmtInt1HrbMars]: {
        url: "https://mars.tiles.divemap.uk/CmtInt1Hrb/{z}/{x}/{y}.png",
        maxZoom: 17,
    },
    [TileMapType.CmtInt1HrbShadedReliefMars]: {
        url: "https://mars.tiles.divemap.uk/CmtInt1HrbShadedRelief/{z}/{x}/{y}.jpeg",
        maxZoom: 13,
    },
};

export function generateMapboxStyle(tileMap: TileMapType): Style {
    const sourceName = `raster-tiles-${tileMap}`;
    const layerName = `layer-tiles-${tileMap}`;
    return {
        version: 8,
        sources: {
            [sourceName]: {
                type: "raster",
                tiles: [TILE_MAPS[tileMap].url],
                tileSize: 256,
                minzoom: 0,
                maxzoom: TILE_MAPS[tileMap].maxZoom,
            },
        },
        layers: [
            {
                id: layerName,
                type: "raster",
                source: sourceName,
                paint: {
                    "raster-opacity": 0.65,
                    "raster-contrast": 0.2,
                },
            },
        ],
        glyphs: "mapbox://fonts/mapbox/{fontstack}/{range}.pbf",
    };
}
