<script lang="ts" setup>
import { useDivemapHead } from "src/util/unhead";

useDivemapHead({ title: "About" });
</script>

<template>
    <main class="page">
        <h1>This is an about page</h1>
        <p>Hi there!</p>
    </main>
</template>
