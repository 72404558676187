import { reactive, readonly } from "vue";
import { UserProfileStateQuery } from "./api/userProfileState.generated";
import { TileMapType } from "./maps/tiles";
import { MapCameraPosition, MapStyle } from "./maps/types";
import { UserLogin } from "./types";
import { updateQueryString } from "./util/url";

export const QUERY_KEY_LAT = "Φ";
export const QUERY_KEY_LNG = "λ";
export const QUERY_KEY_ZOOM = "z";
export const QUERY_KEY_STYLE = "s";
const QUERY_POSITION_PRECISION = 4;
const QUERY_ZOOM_PRECISION = 1;

const DEFAULT_MAP_POSITION: MapCameraPosition = { lat: 55, lng: -3, zoom: 5 };
export const DEFAULT_MAP_STYLE = MapStyle.MAP;

function getInitialMapPosition(): MapCameraPosition {
    const searchParams = new URLSearchParams(window.location.search);
    const lat = parseFloat(searchParams.get(QUERY_KEY_LAT)!);
    const lng = parseFloat(searchParams.get(QUERY_KEY_LNG)!);
    const zoom = parseFloat(searchParams.get(QUERY_KEY_ZOOM)!);
    return lat && lng && zoom ? { lat, lng, zoom } : DEFAULT_MAP_POSITION;
}

function getInitialMapStyle(): MapStyle {
    const searchParams = new URLSearchParams(window.location.search);
    return (searchParams.get(QUERY_KEY_STYLE) as MapStyle) || DEFAULT_MAP_STYLE;
}

interface AppState {
    stateVersion: number;
    user: UserLogin | null;
    username: string | null; // TODO: deprecate in favour of user.username
    distinctId: string | null;
    isSuperuser: boolean;
    permissions: string[];
    authToken: string | null;
    authTokenIssued: number | null;
    authTokenExpires: number | null;
    loggedIn: boolean | null;
    mapPosition: MapCameraPosition;
    mapStyle: MapStyle;
    isMapLoadingSources: boolean;
    tileMap: TileMapType;
    userDiveLogs: UserProfileStateQuery["diveLogs"] | null;
}

const state = reactive({
    stateVersion: 1,
    user: null,
    username: null,
    distinctId: null,
    isSuperuser: false,
    permissions: [],
    authToken: null,
    authTokenIssued: null,
    authTokenExpires: null,
    loggedIn: null,
    mapPosition: getInitialMapPosition(),
    mapStyle: getInitialMapStyle(),
    isMapLoadingSources: false,
    tileMap: TileMapType.OpenStreetMap,
    userDiveLogs: null,
} as AppState);

const store = {
    state: readonly(state),

    // Load stored state from local storage, does not verify data is still valid
    loadFromStorage() {
        const storedStateVersion = Number(localStorage.getItem("stateVersion"));
        if (
            !storedStateVersion ||
            isNaN(storedStateVersion) ||
            storedStateVersion < state.stateVersion
        ) {
            localStorage.clear();
            localStorage.setItem("stateVersion", String(state.stateVersion));
        }

        state.user = JSON.parse(localStorage.getItem("user") || "null");
        state.username = localStorage.getItem("username") || null;
        state.isSuperuser = localStorage.getItem("is-superuser") === "true";
        state.permissions = JSON.parse(
            localStorage.getItem("permissions") || "[]",
        );
        state.authToken = localStorage.getItem("auth-token") || null;
        state.authTokenIssued =
            parseInt(localStorage.getItem("auth-token-issued") as string, 10) ||
            null;
        state.authTokenExpires =
            parseInt(
                localStorage.getItem("auth-token-expires") as string,
                10,
            ) || null;
        if (state.authToken && state.authTokenExpires) {
            state.loggedIn = true;
        }
        const localTileMap = localStorage.getItem("tile-map") as TileMapType;
        if (localTileMap) {
            state.tileMap = localTileMap;
        }
    },

    // Set auth state from a token and payload
    setAuth(token: string, payload: any) {
        state.username = payload.username;
        state.authToken = token;
        state.authTokenIssued = payload.origIat;
        state.authTokenExpires = payload.exp;
        localStorage.setItem("username", state.username!);
        localStorage.setItem("auth-token", state.authToken!);
        localStorage.setItem(
            "auth-token-issued",
            state.authTokenIssued!.toString(),
        );
        localStorage.setItem(
            "auth-token-expires",
            state.authTokenExpires!.toString(),
        );
        state.loggedIn = true;
    },

    // Set user state from a user object
    setUser(user: UserLogin) {
        state.user = user;
        localStorage.setItem("user", JSON.stringify(state.user));
        state.isSuperuser = user.isSuperuser;
        localStorage.setItem("is-superuser", state.isSuperuser.toString());
        state.permissions = user.permissions;
        localStorage.setItem("permissions", JSON.stringify(state.permissions));
    },

    // Clear both auth and user states
    clearAuth() {
        state.user = null;
        state.username = null;
        state.isSuperuser = false;
        state.permissions = [];
        state.authToken = null;
        state.authTokenIssued = null;
        state.authTokenExpires = null;
        state.loggedIn = false;
        localStorage.removeItem("user");
        localStorage.removeItem("username");
        localStorage.removeItem("is-superuser");
        localStorage.removeItem("permissions");
        localStorage.removeItem("auth-token");
        localStorage.removeItem("auth-token-issued");
        localStorage.removeItem("auth-token-expires");
    },

    setDistinctId(distinctId: string) {
        state.distinctId = distinctId;
    },

    setMapPosition({
        lat,
        lng,
        zoom,
    }: {
        lat: number;
        lng: number;
        zoom: number;
    }): void {
        state.mapPosition = { lat, lng, zoom };
        updateQueryString({
            [QUERY_KEY_LAT]: lat.toFixed(QUERY_POSITION_PRECISION),
            [QUERY_KEY_LNG]: lng.toFixed(QUERY_POSITION_PRECISION),
            [QUERY_KEY_ZOOM]: zoom.toFixed(QUERY_ZOOM_PRECISION),
        });
    },

    setMapStyle(style: MapStyle) {
        state.mapStyle = style;
        updateQueryString({
            [QUERY_KEY_STYLE]: style,
        });
    },

    setIsMapLoadingSources(isLoading: boolean) {
        state.isMapLoadingSources = isLoading;
    },

    setTileMap(tileMap: TileMapType) {
        state.tileMap = tileMap;
        localStorage.setItem("tile-map", tileMap);
    },

    setUserDiveLogs(diveLogs: UserProfileStateQuery["diveLogs"] | null) {
        state.userDiveLogs = diveLogs;
    },

    hasUserDivedAtSite(featureId: string): boolean | null {
        if (!state.userDiveLogs) {
            return null;
        }
        return state.userDiveLogs.some(
            (diveLog) => diveLog?.feature?.id === featureId,
        );
    },
};

export default store;
