import store, {
    DEFAULT_MAP_STYLE,
    QUERY_KEY_LAT,
    QUERY_KEY_LNG,
    QUERY_KEY_STYLE,
    QUERY_KEY_ZOOM,
} from "src/store";
import { updateQueryString } from "src/util/url";

export function updateQueryStringFromState(): void {
    const position = store.state.mapPosition;
    const updatedQuery: Record<string, string> = {
        [QUERY_KEY_LAT]: position.lat.toFixed(4),
        [QUERY_KEY_LNG]: position.lng.toFixed(4),
        [QUERY_KEY_ZOOM]: position.zoom.toFixed(2),
    };
    if (store.state.mapStyle !== DEFAULT_MAP_STYLE) {
        updatedQuery[QUERY_KEY_STYLE] = store.state.mapStyle;
    }
    updateQueryString(updatedQuery);
}
