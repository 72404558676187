import jwt_decode from "jwt-decode";
import { AuthCheckDocument } from "./api/authCheck.generated";
import { LoginDocument } from "./api/login.generated";
import { RefreshTokenDocument } from "./api/refreshToken.generated";
import { VerifyTokenDocument } from "./api/verifyToken.generated";
import client, { DEFAULT_FETCH_POLICY } from "./client";
import bus from "./events";
import { loadUserProfile } from "./service/user";
import store from "./store";
const EXPIRY_REFRESH_THRESHOLD = 86400 * 5;
const NO_RESPONSE_ERROR = new Error("No response data");

export async function doLogin(
    username: string,
    password: string,
): Promise<boolean> {
    let response;
    try {
        response = await client.mutate({
            mutation: LoginDocument,
            variables: { username, password },
        });
    } catch (AxiosError) {
        return false;
    }
    if (!response.data) throw NO_RESPONSE_ERROR;
    store.setAuth(
        response.data.tokenAuth.token,
        response.data.tokenAuth.payload,
    );
    const user = response.data.tokenAuth.user;
    store.setUser(user);
    bus.emit("UserLogin", user);

    // Trigger async loading of user profile
    loadUserProfile(user.id);

    return true;
}

export async function doLogout(): Promise<void> {
    bus.emit("UserLogout");
    store.clearAuth();
    return;
}

export async function verifyToken(): Promise<boolean> {
    if (!store.state.authToken) {
        return false;
    }
    try {
        await client.mutate({
            mutation: VerifyTokenDocument,
            variables: { token: store.state.authToken },
        });
    } catch (AxiosError) {
        await doLogout();
        return false;
    }
    return true;
}

export async function refreshToken(): Promise<boolean> {
    if (!store.state.authToken) {
        console.debug("refreshToken: no authToken");
        return false;
    }
    let response;
    try {
        response = await client.mutate({
            mutation: RefreshTokenDocument,
            variables: { token: store.state.authToken },
        });
    } catch (error) {
        console.debug(`refreshToken: failed to refresh (${error})`);
        await doLogout();
        return false;
    }
    if (!response.data) throw NO_RESPONSE_ERROR;
    store.setAuth(
        response.data.refreshToken.token,
        response.data.refreshToken.payload,
    );
    console.debug("refreshToken: success");
    return true;
}

function hasTokenExpired(): boolean {
    if (!store.state.authTokenExpires) {
        return true;
    }
    return store.state.authTokenExpires < Date.now() / 1000;
}

function doesTokenNeedRefresh(threshold: number = 0) {
    // Local check of token validity
    if (!store.state.authTokenExpires) {
        return false;
    }
    if (!store.state.authToken) {
        return false;
    }
    return store.state.authTokenExpires - threshold < Date.now() / 1000;
}

async function refreshTokenIfNeeded(): Promise<void> {
    if (doesTokenNeedRefresh(EXPIRY_REFRESH_THRESHOLD)) {
        console.debug("refreshTokenIfNeeded: Auth token expired, refreshing");
        await refreshToken();
    } else {
        console.debug("refreshTokenIfNeeded: Auth token valid");
    }
}

export async function authCheck(): Promise<boolean> {
    try {
        await client.query({
            query: AuthCheckDocument,
            fetchPolicy: DEFAULT_FETCH_POLICY,
        });
    } catch (AxiosError) {
        return false;
    }
    return true;
}

export function getDecodedAuthToken(token: string): Record<string, unknown> {
    return jwt_decode(token);
}

export function hasPermission(permission: string): boolean {
    if (store.state.permissions.includes(permission)) return true;
    return !!store.state.isSuperuser;
}

// Auth checks we want to do on app load
export async function doAppLoadAuthChecks() {
    if (
        !store.state.loggedIn ||
        !store.state.authToken ||
        !store.state.authTokenIssued ||
        !store.state.authTokenExpires
    ) {
        store.clearAuth();
        return;
    }
    if (hasTokenExpired()) {
        store.clearAuth();
        return;
    }
    await refreshTokenIfNeeded();
}
