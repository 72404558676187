<template>
    <input-field :id="id" :label="label" :description="description">
        <input
            :type="type"
            :id="id"
            :value="modelValue"
            @input="onInput"
            @focus="onFocus"
            @blur="onBlur"
            :placeholder="placeholder"
            :disabled="disabled"
            :required="required"
            :autocomplete="autocomplete"
        />
    </input-field>
</template>

<script lang="ts">
import cuid from "cuid";
import { defineComponent } from "vue";
import InputField from "./InputField.vue";

export default defineComponent({
    components: { InputField },
    emits: {
        "update:modelValue": (value: string) => true,
        focus: (value: string) => true,
        blur: (value: string) => true,
    },
    props: {
        id: {
            type: String,
            required: false,
            default: () => cuid(),
        },
        label: {
            type: String,
            required: true,
        },
        description: {
            type: String,
            required: false,
        },
        placeholder: {
            type: String,
            required: false,
        },
        type: {
            type: String,
            default: "text",
        },
        modelValue: {
            type: String,
            required: false,
        },
        disabled: {
            type: Boolean,
            default: false,
        },
        required: {
            type: Boolean,
            default: false,
        },
        autocomplete: {
            type: String,
        },
    },
    setup(props, context) {
        return {
            onInput: (event: Event) =>
                context.emit(
                    "update:modelValue",
                    (event.target as HTMLInputElement).value,
                ),
            onFocus: (event: Event) =>
                context.emit("focus", (event.target as HTMLInputElement).value),
            onBlur: (event: Event) =>
                context.emit("blur", (event.target as HTMLInputElement).value),
        };
    },
});
</script>
