import posthog, { Properties } from "posthog-js";
import { captureException } from "./sentry";
import { UserLogin } from "src/types";
import store from "src/store";

const POSTHOG_TOKEN = import.meta.env.VITE_POSTHOG_TOKEN;

let posthogReady = false;

export function initPosthog() {
    if (!POSTHOG_TOKEN) {
        console.warn("No posthog token");
        return;
    }
    posthog.init(POSTHOG_TOKEN, { api_host: "https://eu.posthog.com" });
    store.setDistinctId(posthog.get_distinct_id());
    posthogReady = true;
}

export async function trackEvent(
    event_name: string,
    properties: Properties = {},
) {
    if (!posthogReady) {
        console.debug("Event", {
            event_name,
            properties: properties,
        });
        return;
    }
    try {
        posthog.capture(event_name, properties);
    } catch (exception) {
        captureException(exception);
    }
}

export async function identifyUser(user: UserLogin) {
    try {
        posthog.identify(user.id, {
            name: user.username,
        });
    } catch (exception) {
        captureException(exception);
    }
}

export async function resetUser() {
    posthog.reset();
}

export function renderSurvey(id: string, selector: string) {
    // Seems rather undocumented
    // See https://github.com/PostHog/posthog-js/pull/1324
    posthog.renderSurvey(id, selector);
    console.log(`Attempted to render survey ${id} using selector ${selector}`);
}
