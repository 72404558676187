import { UserProfileStateDocument } from "src/api/userProfileState.generated";
import client from "src/client";
import store from "src/store";

export async function loadUserProfile(user: string) {
    const response = await client.query({
        query: UserProfileStateDocument,
        variables: { user },
    });
    store.setUserDiveLogs(response.data.diveLogs);
}
