import * as Sentry from "@sentry/vue";
import { UserLogin } from "src/types";
import { App } from "vue";
import { Router } from "vue-router";

export function initSentry(app: App, router: Router) {
    if (!import.meta.env.VITE_SENTRY_DSN) return;
    Sentry.init({
        app,
        dsn: import.meta.env.VITE_SENTRY_DSN,
        integrations: [
            new Sentry.BrowserTracing({
                routingInstrumentation: Sentry.vueRouterInstrumentation(router),
                tracePropagationTargets: ["localhost", "api.divemap.uk"],
            }),
            new Sentry.Replay({
                maskAllText: false,
                maskAllInputs: false,
                blockAllMedia: false,
            }),
        ],
        // Set tracesSampleRate to 1.0 to capture 100%
        // of transactions for performance monitoring.
        // We recommend adjusting this value in production
        tracesSampleRate: 1.0,
        // Vue integration: https://docs.sentry.io/platforms/javascript/guides/vue/features/component-tracking/
        trackComponents: true,
        // Vue will not call its logError internally. This means that errors occurring
        // in the Vue renderer will not show up in the developer console. If you want
        // to preserve this functionality, make sure to pass the logErrors: true option.
        logErrors: true,
        // Replay feature
        replaysSessionSampleRate: 1.0,
        replaysOnErrorSampleRate: 1.0,
    });
}

export function identifyUser(user: UserLogin) {
    Sentry.setUser({
        id: user.id,
        username: user.username,
        email: user.email,
    });
}

// Proxy function to make importing easier
export function addBreadcrumb(breadcrumb: Sentry.Breadcrumb) {
    Sentry.addBreadcrumb(breadcrumb);
}

// Proxy function to make importing easier
export function captureMessage(message: string) {
    Sentry.captureMessage(message);
    console.error(message);
}

// Proxy function to make importing easier
export function captureException(exception: any) {
    Sentry.captureException(exception);
    console.error(exception);
}
