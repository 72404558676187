<script lang="ts" setup>
import { ref } from "vue";
import TextField from "./fields/TextField.vue";
import AsyncSubmitButton from "./AsyncSubmitButton.vue";
import jwt_decode from "jwt-decode";
import { isString, isRecord } from "typed-assert";
import { useRouter } from "vue-router";
import InputField from "./fields/InputField.vue";
import client from "src/client";
import {
    RegisterWithEmailTokenDocument,
    RegisterWithEmailTokenMutation,
} from "src/api/registerWithEmailToken.generated";
import { FetchResult, isApolloError } from "@apollo/client/core";
import { isError } from "src/error";
import store from "src/store";
import bus from "src/events";

const props = defineProps<{
    token: string;
}>();

const username = ref("");
const isBusy = ref(false);
let email = "";

const router = useRouter();

try {
    const tokenPayload = jwt_decode(props.token);
    isRecord(tokenPayload);
    isString(tokenPayload?.email);
    email = tokenPayload?.email;
} catch (e) {
    alert("Invalid token");
    router.push({ name: "login" });
}

async function handleRegister() {
    isBusy.value = true;
    let response: FetchResult<RegisterWithEmailTokenMutation>;
    try {
        response = await client.mutate({
            mutation: RegisterWithEmailTokenDocument,
            variables: {
                emailAuthToken: props.token,
                username: username.value,
            },
        });
    } catch (e) {
        isBusy.value = false;
        if (!isError(e)) {
            alert("An unknown error occurred");
            return;
        }
        if (!isApolloError(e)) {
            alert("An unknown error occurred");
            return;
        }
        alert(e);
        return;
    }

    const data = response.data?.registerUserFromEmailToken;
    if (!data) {
        alert("Server returned no data");
        router.push({ name: "login" });
        return;
    }

    store.setAuth(data.token, data.payload);
    store.setUser(data.user);
    bus.emit("UserRegistered", data.user);
    router.push({ name: "map" });
}
</script>

<template>
    <form @submit.prevent="handleRegister">
        <p class="mb-2">
            You're creating a new account on divemap. After this you can login
            again using your email address.
        </p>

        <InputField id="emailAddress" label="Email address">
            {{ email }}
        </InputField>

        <TextField
            label="User name"
            v-model="username"
            required
            autocomplete="email"
            :disabled="isBusy"
            description="This name will be used if you post logs or dive sites. User name must only contain letters, numbers, dashes, and underscores."
        />
        <AsyncSubmitButton class="btn btn-primary" type="submit" :busy="isBusy">
            Create account
        </AsyncSubmitButton>
    </form>
</template>
