<template>
    <button type="submit" class="btn" :disabled="busy || disabled">
        <span class="pr-1" v-if="busy">
            <font-awesome-icon
                :icon="['far', 'spinner-third']"
                class="animate-spin"
            />
        </span>
        <slot />
    </button>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
    props: {
        busy: {
            type: Boolean,
            required: true,
        },
        disabled: {
            type: Boolean,
            default: false,
        },
    },
});
</script>
