// Ensure sentry is imported first so it can patch things
import { initSentry } from "./service/sentry";

// Everything else
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { createHead } from "@unhead/vue";
import { DefaultApolloClient } from "@vue/apollo-composable";
import { createApp, h, provide } from "vue";
import App from "./App.vue";
import { doAppLoadAuthChecks } from "./auth";
import client from "./client";
import bus from "./events";
import "./icons";
import router from "./router";
import { initUserTracking } from "./service/analytics";
import store from "./store";

import { loadUserProfile } from "./service/user";
import "./styles/main.sass";

// Expose objects for debugging
(window as any).store = store;

const app = createApp({
    setup() {
        provide(DefaultApolloClient, client);
    },
    render: () => h(App),
});

// Vue additions
app.use(router);

const head = createHead();
app.use(head);

app.component("font-awesome-icon", FontAwesomeIcon);

// Install our own services
initSentry(app, router);
initUserTracking();

async function restoreStoreState() {
    store.loadFromStorage();
    await doAppLoadAuthChecks();
    // If user is logged in, load their profile
    if (store.state.user?.id) {
        console.log("Loading user profile for " + store.state.user.id);
        loadUserProfile(store.state.user.id);
    }
    bus.emit("StoreReady"); // It's a bit messy this is triggered from here
}

async function startApp() {
    // Check and refresh auth state if needed
    await restoreStoreState();

    // Mount (and render) the app
    app.mount("#app");

    // Remove preload styling from the body
    document.body.classList.remove("preload");

    bus.emit("AppReady", { loggedIn: store.state.loggedIn || false });
}

startApp();
